<template>
  <div question-image-selector>
    <label v-show="noImage" class="button-upload">
      <basic-button @click="onUpload" class="btn-upload-image" theme="white-small" v-ripple>
        <svg-add-image />
        이미지 파일 선택
      </basic-button>
    </label>
    <div class="previews">
      <cdn-img v-for="(src, idx) in value" :key="idx" class="preview-image" :src="src" />
      <color-button class="button-remove" @click="onResetImages" type="light-gray" v-show="!noImage">
        <svg-trash />
      </color-button>
    </div>
    <input type="file" accept="image/jpeg,image/png" ref="fileInput" @change="onFileSelected" multiple />
  </div>
</template>

<script>
import CdnImg from '@shared/components/common/CdnImg.vue';
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgTrash from '@shared/graphics/svg-trash.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import SvgAddImage from '@/views/graphics/svg-add-image.vue';
import ripple from '@/directive/ripple';

export default {
  components: { BasicButton, ColorButton, SvgAddImage, SvgTrash, CdnImg },
  directives: { ripple },
  lexicon: 'coaching',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    maxAmount: {
      type: Number,
      default: 1,
    },
    maxMB: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    noImage() {
      return this.value === undefined || this.value?.length === 0 || this.value[0] === '';
    },
  },
  methods: {
    onUpload() {
      this.$refs.fileInput.click();
    },
    async onFileSelected(ev) {
      try {
        const files = Array.from(ev?.target?.files);
        if (files?.length > this.maxAmount) throw new Error('FILE_SELECT_FILE_AMOUNT_EXCEED');
        const fileSizes = files.map(f => f.size || 0);
        const targetSize = 1024 * 1024 * this.maxMB;
        if (fileSizes.some(fs => fs > targetSize)) throw new Error('최대 1mb 의 이미지만 올릴 수 있습니다');
        await this.uploadFiles(files);
      } catch (err) {
        this.$toast(`_.${err}`);
      }
    },
    async uploadFiles(fileData) {
      const srcs = [];
      for (const file of fileData) {
        const src = await this.$services.aws.upload(file);
        srcs.push(src?.key);
      }
      this.$emit('input', srcs);
    },
    onResetImages() {
      this.$emit('input', []);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[question-image-selector] {
  > input[type='file'] {display: none;}
  .previews {.flex;.flex-ai(center);.rel;
    > * + * { .abs; .t(20); .r(20); .p(0); .wh(30, 30);}
  }
  .preview-image { .w(100%);.br(12);}
  .btn-upload-image { .mt(5); }
  .button-upload {.flex;.flex-ai(center);}
  .button-upload [color-button] {.h(40);.br(12);border: 1px dashed #a4a3ae;.c(#787781);.mr(12);.flex;.flex-ai(center);}
  .upload-description { .fs(12);.c(#a4a3ae);.lh(14.4);.w(150)}
  [svg-camera-outline] { stroke: #fff;.wh(24, 24);}
}
</style>
