<template>
  <div coach-news-item-edit>
    <div class="news-item-edit-inner">
      <h1>소식 게시물 작성</h1>
      <div class="form-row">
        <selector v-model="newsInfo.category" :list="categoryList" theme="white" class="left-item" />
        <text-input-alt v-model="newsInfo.title" class="right-item" placeholder="게시물 제목을 입력해 주세요. 36자 이상일 경우 말줄임표가 생길 수 있습니다." />
        <a href="https://bigpic.atlassian.net/wiki/spaces/BD/pages/1724022785" class="link-markdown-help" target="_blank">마크다운</a>
      </div>
      <div class="form-row">
        <p class="left-item required">썸네일 이미지<br />(848x424)</p>
        <div class="right-item">
          <coach-image-selector v-model="thumbnailImageUrls" />
          <text-input-alt v-model="newsInfo.thumbnailImageTitle" placeholder="썸네일 이미지를 설명할 alt 태그를 입력해 주세요" />
        </div>
      </div>
      <textarea class="input-content" placeholder="본문 텍스트를 작성해 주세요" v-model="newsInfo.content" />
      <div class="form-row">
        <p class="left-item">CTA 버튼</p>
        <div class="right-item">
          <text-input-alt v-model="newsInfo.ctaButtonTitle" placeholder="버튼 레이블 입력. 최대 20자" />
          <text-input-alt v-model="newsInfo.ctaButtonLink" placeholder="CTA 버튼으로 이동할 링크를 입력해 주세요" />
        </div>
      </div>
      <div class="form-row">
        <p class="left-item">연관코치</p>
        <coach-suggest v-model="relatedCoaches" :text.sync="coachSuggestText" class="right-item" />
      </div>
      <hr />
      <div class="form-row">
        <p class="left-item">OG 디스크립션</p>
        <text-input-alt v-model="newsInfo.ogDescription" placeholder="컨텐츠 공유시 노출될 설명을 입력해 주세요" class="right-item" />
      </div>
      <div class="form-row">
        <p class="left-item">OG 키워드</p>
        <text-input-alt v-model="newsInfo.ogKeywords" placeholder="컨텐츠의 대표 키워드를 입력해 주세요. 단어로 입력하고 쉼표로 구분해주세요" class="right-item" />
      </div>
      <div class="form-row">
        <p class="left-item">게시물 설정</p>
        <div class="right-item article-visible">
          <radio-group-em theme="radio-blue" id="article-visible" v-model="newsInfo.isVisible" :options="[{ label: '공개', value: true }, {label: '비공개', value: false}]" horizontal />
          <p>비공개 상태에서는 관리자계정 외 게시물을 볼 수 없습니다.</p>
        </div>
      </div>
    </div>
    <div class="button-group">
      <basic-button theme="white" @click="onCancel">작성 취소</basic-button>
      <basic-button theme="blue" @click="onSubmit">
        <svg-check-thin />
        확인
      </basic-button>
    </div>
  </div>
</template>
<script>
import _omit from 'lodash/omit';
import { getter } from 'shared/utils/storeUtils';
import Selector from '@/views/components/coaching/Selector.vue';
import TextInputAlt from '@/views/components/coaching/TextInputAlt.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import CoachImageSelector from '@/views/components/coaching/coach-detail/CoachImageSelector.vue';
import RadioGroupEm from '@/views/components/coaching/RadioGroupEm.vue';
import SvgCheckThin from '@/views/graphics/svg-check-thin.vue';
import CoachSuggest from '@/views/components/coaching/coach-suggest/CoachSuggest.vue';

export default {
  name: 'CoachNewsItemEdit',
  components: { CoachSuggest, SvgCheckThin, RadioGroupEm, CoachImageSelector, TextInputAlt, Selector, BasicButton },
  lexicon: 'coaching.news',
  data: () => ({
    /** @type{CoachNews} */
    newsInfo: {},
    coachSuggestText: '',
  }),
  computed: {
    userId: getter('auth', 'userId'),
    categoryList() {
      return [
        { value: 'INTERVIEW', label: this.$t('_.category.INTERVIEW') },
        { value: 'NEWS', label: this.$t('_.category.NEWS') },
        { value: 'CONTENTS', label: this.$t('_.category.CONTENTS') },
      ];
    },
    thumbnailImageUrls: {
      get() {
        if (!this.newsInfo?.thumbnailImageUrl) return [];
        return [this.newsInfo?.thumbnailImageUrl];
      },
      set(v) {
        const url = v?.[0] ?? '';
        this.$set(this.newsInfo, 'thumbnailImageUrl', url);
      },
    },
    relatedCoaches: {
      get() {
        return this.newsInfo?.relatedCoaches || [];
      },
      set(v) {
        this.$set(this.newsInfo, 'relatedCoaches', v);
      },
    },
    relatedCoachesId() {
      return this.relatedCoaches.map(c => c?.coachId);
    },
    newsInfoModify() {
      return { ..._omit(this.newsInfo, ['relatedCoaches', 'insertDatetime', 'updateDatetime']), relatedCoaches: this.relatedCoachesId, writerUserId: this.userId };
    },
  },
  methods: {
    async onSubmit() {
      // 나중에 폼 밸리데이션으로 변경
      if (!this.newsInfo?.thumbnailImageUrl || this.newsInfo?.thumbnailImageUrl === '') {
        this.$toast('썸네일 이미지를 선택하지 않았습니다.', { translate: false, type: 'fail' });
        return;
      }
      if (this.relatedCoaches?.length > 3) {
        this.$toast('연관 코치는 최대 3명까지 등록 가능합니다', { translate: false, type: 'fail' });
        return;
      }
      const newsInfo = this.newsInfo.newsId
        ? await this.$services.news.modifyNews(this.newsInfoModify)
        : await this.$services.news.createNews(this.newsInfoModify);
      this.$router.replace({ name: 'CoachNewsItem', params: { newsId: newsInfo.newsId } });
    },
    async onCancel() {
      this.$router.replace({ name: 'CoachNews' });
    },
  },
  async asyncData({ store, route, services }) {
    let defaultNewsInfo = {
      category: 'INTERVIEW',
      title: '',
      thumbnailImageTitle: '',
      thumbnailImageUrl: null,
      ctaButtonTitle: '',
      ctaButtonLink: '',
      relatedCoaches: null,
      isVisible: false,
      writerUserId: store.state?.auth?.userId,
      viewCount: 0,
    };
    const newsId = route?.params?.newsId ?? '';
    if (newsId !== '') {
      defaultNewsInfo = await services.news.getNewsDetail(newsId);
    }
    return { newsInfo: defaultNewsInfo };
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-news-item-edit] {.bgc(#fff);.pt(50);.pb(50);
  .news-item-edit-inner {.responsive-w(1024);.m(auto);
    > * + * { .mt(20);}
  }
  .form-row {
    .flex;.flex-row;
    p.left-item.required:before {content: '*';.c(red);}
    .left-item {.w(200px);.mr(10);}
    .right-item {flex: 1;}
    .right-item.article-visible {.flex;
      [radio-group-em] {.mr(10);}
      > p { .c(#ddd) }
    }
  }
  .link-markdown-help {text-decoration: underline;}
  .input-content {.bgc(#fff);border: solid 1px #ddd;.br(4);.w(100%);.h(500);.p(12, 15);
    &::placeholder {.c(#ddd)}
  }
  .button-group {.flex;.flex-jc(center);.flex-ai(center);.mt(30);
    [basic-button] + [basic-button] {.ml(20)}
  }
  hr {.h(1);.bgc(#ddd);}
  @media (@tp-down) {
    .form-row { .flex-column;
      .left-item {.w(100%);.mr(unset);}
      .right-item { flex: 0;.w(100%);}
    }
    .news-item-edit-inner {.p(0, 20)}
  }
}
</style>
