<script>
import Selector from '@shared/components/common/Selector.vue';

export default {
  extends: Selector,
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[selector] {
  > div > a {.w(auto);}
  > div {border: solid 1px;border-color: #d5d5de;}
  > div.open, > div:hover {border-color: #191919;}
  //> div > a:focused {border-color: #2C81FF;}
  .open ul {.z(1)}
  &.white, &.white-reverse {
    //input:focus {
    //  border: solid 1px #2C81FF;
    //}
    ul { list-style: none;padding-inline-start: 0;.scroll;}
    li {list-style: none;.block;.c(black);
      &:hover {.bgc(rgba(164, 163, 174, .1))}
      &.selected {.bgc(rgba(164, 163, 174, .3))}
    }
  }
  &.white-reverse {
    > div.open {.br(0); .br-b(6);border: solid 1px #191919;}
    > div a i {.t-r(180deg)}
    > div.open a i {.t-r(0deg)}
    ul {.abs;.rb(0, 45px);.br-t(6);}
    > div.open ul {
      border: solid 1px #191919;
    }
  }
}
</style>
