<template>
  <component :is="tag" input-suggest :class="{'list-visible': listVisible}" v-click-outside="onClickOutside" :data-theme="theme">
    <slot name="prepend"></slot>
    <input type="text" :value="text" @input="onTextChange" @focus="onFocus" ref="input" />
    <i class="focus-indicator" />
    <component :is="listTag" class="suggest-list" v-show="listVisible">
      <slot name="no-suggestion" v-if="noSuggestion"></slot>
      <component v-for="suggestion in suggestionList" :is="itemTag" class="item" v-bind="suggestion" :key="getKey(suggestion)" @select="_onSelect" />
    </component>
    <slot name="append"></slot>
  </component>
</template>

<script>
import InputSuggestItem from '@/views/components/coaching/InputSuggestItem.vue';

export default {
  name: 'InputSuggest',
  data: () => ({
    suggestions: [],
    listVisible: false,
  }),
  props: {
    // !주의! 반드시 v-model로 value값을 바인드하고, :text.sync로 text값도 함께 바인드해야함
    value: {
      type: [String, Number, Object, Array],
      default: '',
    },
    // <input-suggest :sync.text="..." /> -> <input-suggest :text="..." @text:update="..."  />
    text: {
      type: String,
      default: '',
    },
    onSelect: {
      type: Function,
      // ({ value, setValue, setText }) => { ...doSomething }
      default: ({ value, setText, setValue }) => {
        setText(value.text);
        setValue(value.value);
      },
    },
    getItemProp: {
      type: Function,
      default: ((i, v) => ({ text: i, value: i, key: i, checked: i === v })),
    },
    tag: {
      type: [String, Object],
      default: 'div',
    },
    listTag: {
      type: [String, Object],
      default: 'ul',
    },
    itemTag: {
      type: [String, Object],
      default: () => InputSuggestItem,
    },
    suggest: {
      type: Function,
      default: async () => [],
    },
    theme: {
      type: String,
      default: 'base',
    },
  },
  computed: {
    suggestionList() {
      return this.suggestions.map(itm => this.getItemProp(itm, this.value));
    },
    noSuggestion() {
      return (this.suggestions?.length || 0) === 0;
    },
  },
  methods: {
    async showList(text) {
      this.suggestions = await this.suggest(text);
      this.listVisible = true;
    },
    onTextChange(ev) {
      const value = ev?.target?.value;
      this.setText(value);
      this.showList(value);
    },
    getKey(suggestion) {
      return suggestion?.key || suggestion;
    },
    setText(text) {
      this.$emit('update:text', text);
    },
    setValue(value) {
      this.$emit('input', value);
    },
    _onSelect(value) {
      this.onSelect({ value, setText: this.setText.bind(this), setValue: this.setValue.bind(this) });
      this.listVisible = false;
    },
    /** @param {FocusEvent} ev */
    onFocus(ev) {
      // 외부에서 클릭으로 발생한 이벤트가 아닐 경우 리스트를 표시하지 않는다
      // 목록이 닫히면서 자동으로 다시 포커싱이 발생하여 목록이 표기되는 일을 방지
      if (!ev?.sourceCapabilities) return;
      this.showList(this.text);
    },
    onClickOutside() {
      this.listVisible = false;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
@c-input-gray: #ddd;
@c-input-gray-hover: #aaa;

[input-suggest] {.rel;
  .suggest-list {.abs;.lt(0, 48);.w(100%);.max-h(300);list-style: none;.bgc(white);.z(100)}
  .focus-indicator {.hide}
  // coach-suggest에서만 사용
  &[data-theme='coach-suggest'] {.flex;flex-wrap: wrap; flex: 1;.bgc(@c-white);.br(4);
    @suggest-gap: 5px;
    //.p(15,12);
    .p(10, 7, 15, 12);
    .suggest-list {.t(100%);.bgc(#fff);.scroll;border: solid 1px @c-input-gray;}
    > *:not(.focus-indicator):not(.suggest-list) { display: inline-block;.ml(@suggest-gap);.mt(@suggest-gap)}
    &::selection {
      background: rgba(201, 222, 255, 0.5);
    }
    &::-moz-selection {
      background: rgba(201, 222, 255, 0.5);
    }
    .focus-indicator { .block;.abs;left: 0;top: 0;bottom: 0;right: 0;pointer-events: none;border: solid 1px @c-input-gray;.br(4);}
    &:hover .focus-indicator {border-color: @c-input-gray-hover;}
    > input[type='text'] {.fs(14);.bgc(transparent);flex: 1;.min-w(200);.pl(0);.br(0);
      display: inline-block;
      transition: border .3s ease-out;
      &::placeholder { .c(@c-input-gray)}
      &:focus ~ .focus-indicator {
        border: solid 1px @blue-primary;
      }
    }
  }
}
</style>
