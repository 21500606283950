<template>
  <input-suggest :suggest="onSuggestCoach" v-model="_value"
    theme="coach-suggest"
    coach-suggest :text.sync="syncedText"
    :on-select="onCoachSelect"
    :item-tag="CoachSuggestItem"
    :get-item-prop="getItemProp"
  >
    <template #prepend>
      <button v-for="coach in value" :key="getCoachId(coach)" class="coach-suggest-tag" @click="() => removeCoach(coach)" :data-coach-id="getCoachId(coach)">
        {{ getCoachNickname(coach) }}
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 1L1 11" stroke="currentColor" />
          <path d="M1 1L11 11" stroke="currentColor" />
        </svg>
      </button>
    </template>
  </input-suggest>
</template>

<script>
import InputSuggest from '@/views/components/coaching/InputSuggest.vue';
import CoachSuggestItem from '@/views/components/coaching/coach-suggest/CoachSuggestItem.vue';

export default {
  components: { InputSuggest },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    syncedText: {
      get() {
        return this.text;
      },
      set(v) {
        this.$emit('update:text', v);
      },
    },
    CoachSuggestItem: () => CoachSuggestItem,
    coachIds() {
      return this.value?.map(c => c?.coachId);
    },
    coachIdMap() {
      const m = new Map();
      this.coachIds.forEach(v => m.set(v, true));
      return m;
    },
    _value: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  methods: {
    async onSuggestCoach(text) {
      const reqCoaches = await this.$services.coaching.getCoachList({ name: text, silent: true });
      const coaches = (/** @type{Coach[]} */reqCoaches?.items ?? []).sort(a => (this.coachIdMap.get(a?.coachId) ? 1 : -1));
      return coaches;
    },
    /**
     * @param {Coach} value
     * @param setValue
     * @param setText
     */
    onCoachSelect({ value, setValue, setText }) {
      setValue([...this.value, value]);
      setText('');
    },
    /**
     * @param {Coach} coachInfo
     */
    getItemProp(coachInfo) {
      return { coachInfo, key: coachInfo?.coachId, selected: this.coachIdMap.get(coachInfo?.coachId) };
    },
    getCoachNickname(coach) {
      return coach?.user?.nickname;
    },
    getCoachId(coach) {
      return coach?.coachId;
    },
    /**
     * @param {Coach} coach
     */
    removeCoach(coach) {
      this._value = this._value.filter(c => c?.coachId !== coach?.coachId);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-suggest] {
  .coach-suggest-tag {.flex;.flex-ai(center);.br(4);.bgc(#ddd);.p(5);
    svg { .ml(5);.c(#191919)}

    &:hover {.bgc(#444);.c(#fff);
      svg {.c(#fff)}
    }
    &:focus {border: solid 1px @blue-primary;}
  }
}
</style>
