<template>
  <div :class="[theme, disabled && 'disabled']" selector>
    <div :class="{open}">
      <a @click="toggle">
        <img v-if="selectedItem.img" :src="selectedItem.img">
        <label v-if="subLabel">{{ subLabel }}</label>
        <input :placeholder="placeholder" :value="selectedItem.label" readonly>
        <i>
          <svg-angle />
        </i>
      </a>
      <ul v-if="open">
        <li v-for="item in listForRender" :key="item.value" :class="{selected: value === item.value }" :data-value="item.value" @mousedown="select(item)">
          <img v-if="item.img" :src="item.img">
          <span v-if="item.label">{{ item.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SvgAngle from '@shared/graphics/svg-angle.vue';

export default {
  name: 'Selector',
  components: { SvgAngle },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    theme: { type: String, default: '' },
    subLabel: { type: String, default: '' },
    placeholder: { type: String, default: null },
    value: { type: [String, Number], default: null },
    emptyValue: { type: Object, default: () => ({ value: null }) },
    list: {
      type: [
        /** @type {Array<{value: number|string, label?: string, img?: string}>|Array<number|string>} */ Array,
        /** @type {string | number} */ Object,
      ],
default: null,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    /**
     * @type {{value: number|string, label?: string, img?: string}[]}
     */
    listForRender() {
      if (!this.list) return [];
      if (Array.isArray(this.list)) {
        return this.list.map(item => (typeof item === 'object' ? item : ({ value: item, label: item })));
      }
        return Object.keys(this.list).map(key => ({ value: key, label: this.list[key] }));
    },
    selectedItem() {
      return this.listForRender.find(item => item.value === this.value) ?? this.emptyValue;
    },
  },
  watch: {
    selectedItem(v) {
      if (v === this.emptyValue) this.$emit('change', null);
    },
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.open = this.listForRender.length ? !this.open : false;
      if (this.open) {
        this.$nextTick(() => {
          /** @type {HTMLElement} */
          const selectedElement = this.$el.querySelector(`[data-value="${this.value}"]`);
          if (selectedElement) this.$el.querySelector('ul').scroll(0, selectedElement.offsetTop);
        });
      }
    },
    select(item) {
      this.$emit('change', item.value);
      this.open = false;
    },
    cancelHandler(e) {
      if (!this.$el.contains(e.target)) this.open = false;
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.cancelHandler);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.cancelHandler);
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[selector] { .h(48); .block; .rel;
  > div { .wf; .br(6); .-a(#d5d5de); .block; .bgc(#fff);
    a { .flex; .wh(100%, 48); .p(0, 10); .rel; .pointer; .items-center;
      img { .wh(18); .mr(6); }
      label { white-space: nowrap; .mr(4);
        &:after {content: ' : '}
      }
      input { .-a; .wf; }
      i { .block; .abs; .wh(40, 100%); .rt; .tc;
        svg { .wh(14, 100%);
          .stroke-target { stroke: #787781; }
        }
      }
    }
    ul { .wf; .max-h(160); .scroll; .rel; .bgc(#fff);
      li { .p(10); .fs(13); .c(#787781); .pointer;
        @{hover-press}, &.selected { .bgc(#f4f4f5); }
        &.selected { .c(@c-title-black); }
        img { .wh(18); .ib; .vam; .mr(6); }
        span { .ib; .vam; }
      }
    }
    &.open {
      a {
        i { transform: rotate(180deg); }
      }
    }
  }

  &.dark { .h(44);
    > div { .-a(@c-w01); .bgc(#161a1d);
      a { .h(44);
        input { .bgc(#161a1d); .c(#fff); }
      }
      ul { .bgc(#161a1d);
        li { .c(@c-w06);
          @{hover-press}, &.selected { .bgc(#313131); }
          &.selected { .c(#fff); }
        }
      }
    }
  }

  &.disabled {
    > div { .bgc(#f2f2f4);
      > a > input { .bgc(#f2f2f4);}
    }
  }
}
</style>
