<template>
  <button coach-suggest-item @click="onClick" :class="{selected}" :data-coach-id="coachId">{{ nickname }}</button>
</template>

<script>
export default {
  name: 'CoachSuggestItem',
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ci() {
      return /** @type{Coach} */ this.coachInfo;
    },
    nickname() {
      return this.ci?.user?.nickname ?? '';
    },
    coachId() {
      return this.ci?.coachId ?? '';
    },
  },
  methods: {
    onClick() {
      if (this.selected) return;
      this.$emit('select', this.coachInfo);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-suggest-item] {.block;.w(100%);.p(5);text-align: left;
  &.selected {.bgc(#444);.c(#fff);cursor: default;}
  &:not(.selected) {cursor: pointer;
    &:hover {.bgc(#ddd)}
    &:focus {border: solid 1px @blue-primary;}
  }
}
</style>
