<template>
  <li @click="$emit('select', {value, text})" input-suggest-item :aria-checked="checked" role="checkbox">
    {{ text }}
  </li>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[input-suggest-item] {.wh(100%, 30);
}
</style>
