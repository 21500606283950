<template>
  <!-- COG-307의 디자인 스펙이 기존 TextInput과 완전히 달라 새롭게 만든 컴포넌트-->
  <!-- https://bigpic.atlassian.net/browse/COG-307 -->
  <!-- fallthrough attributes 는 여전히 사용 가능 -->
  <div text-input-alt :class="{error: hasError, passed}" :data-theme="theme">
    <input v-if="allowEnter" :type="type" v-bind="inputAttrs" :disabled="disabled" :value="value" @input="onInput" />
    <input v-else :type="type" v-bind="inputAttrs" :disabled="disabled" :value="value" @input="onInput" @keypress.prevent.enter />
    <span class="input-state-icon" />
    <p class="desc-area" v-show="!hasError">
      <slot name="desc"></slot>
    </p>
    <p class="error-area" v-show="hasError">
      <slot name="error"></slot>
    </p>
    <p class="passed-area" v-show="passed">
      <slot name="passed"></slot>
    </p>
  </div>
</template>

<script>
import _omit from 'lodash/omit';

export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {},
    value: {},
    passed: {
      type: Boolean,
      default: false,
    },
    noReset: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'base',
    },
    allowEnter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputAttrs() {
      return _omit(this.$attrs, ['type', 'class', 'errors', 'value', 'passed']);
    },
    hasError() {
      if (this.default) return false;
      return (this.errors?.length ?? 0) >= 1;
    },
  },
  methods: {
    onInput(ev) {
      if (this.type === 'text') this.$emit('input', ev.target.value);
      if (this.type === 'number') {
        const value = Number(ev.target.value);
        this.$emit('input', Number.isNaN(value) ? 0 : value);
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
@c-input-gray: #ddd;
@c-input-gray-hover: #aaa;

[text-input-alt] {.rel;text-align: left;
  > input {.bgc(@c-white);border: solid 1px @c-input-gray;.br(4);.p(15, 12);.fs(14);
    transition: border .3s ease-out;
    &::placeholder { .c(@c-input-gray)}
    &:hover {border-color: @c-input-gray-hover;}
    &:focus {border-color: @blue-primary;}
    &:disabled {.c(@gray-400);.bgc(@gray-200)}
  }
  > input::selection {
    background: rgba(201, 222, 255, 0.5);
  }
  > input::-moz-selection {
    background: rgba(201, 222, 255, 0.5);
  }
  > .input-state-icon {
    content: '';.abs;.rt(10, 12);pointer-events: none;;
  }
  .error-area, .desc-area {.fs(12);.mt(8);.h(15)}
  .desc-area {.c(@gray-600);}
  .error-area {.c(@red-desc);}
  // input-icon-reset.svg 어떻게 쓸지 확인 필요
  &.passed {
    > .input-state-icon {.bg('@{img}/coaching/input-icon-success.svg');.wh(24); }
  }
  &.error {
    > input { border-color: @red-desc; }
    > .input-state-icon {.bg('@{img}/coaching/input-icon-error.svg');.wh(24); }
    .desc-area {.hide}
  }
  &[data-theme='base-align-center'] {
    > input { text-align: center; }
  }
}
</style>
